<template>
    <div v-if="status" :class="'alert alert-' + alerts() + ' alert-dismissible'">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
        <h5><i :class="'icon ' + icons()"></i> {{ alerts() }}</h5>
        <span v-html="message"></span>
    </div>

</template>

<script>
export default {
    props: {
        type: {
			type: Number,
			default: 1,   // 1 => success, 2 => warning, 3 => danger
		},
        status: {
			type: Boolean,
			default: false,
		},
        message: {
            type: String,
            default: ''
        }
    },
    methods: {
        alerts() {
            switch (this.type) {
                case 1:
                    return 'success';
                    break;

                case 2:
                    return 'warning';
                    break;
            
                case 3:
                    return 'danger';
                    break;
            
                default:
                    return 'info';
                    break;
            }
        },
        icons() {
            switch (this.type) {
                case 1:
                    return 'fas fa-check';
                    break;

                case 2:
                    return 'fas fa-exclamation-triangle';
                    break;
            
                case 3:
                    return 'fas fa-ban';
                    break;
            
                default:
                    return 'fas fa-info';
                    break;
            }
        }
    }
}
</script>