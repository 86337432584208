<template>
    <!-- Content Header (Page header) -->
      <div class="content-header">
        <div class="container-fluid">
          <div class="row mb-2">
            <div class="col-sm-6">
              <h1  v-if="title" class="m-0">
                {{ title }}
                <i class="icon" v-if="icon">

                </i>
              </h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item active">Dashboard v1</li>
              </ol>
            </div>
            <!-- /.col -->
          </div>
          <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->
</template>


<script>
import $ from 'jquery';
export default {
    props: {
        title: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        } 
    },
    beforeMount(){
        let title=this.$store.state.app.name;
        title = this.title? `${this.title} | ${title}` :title;
        $('title').html(title);

    },
    methods: {
        isActive(){
            if(typeof this.link=== "object") {
                return this.$route.name=== this.link.name;
            } else {
                return this.$route.path=== this.link;
            }
        }
    }  
}
</script>