<template>
    <div class="tambah-posts">
        ini adalah component Tambah Item
    </div>
</template>

<script>
    export default {

    }
</script>