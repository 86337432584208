
//import axios from 'axios'
//import axios from 'axios'

//window.axios = axios;
//import './bootstrap';
import axios from 'axios'

window.axios = axios;

axios.defaults.withCredentials = true;
//axios.defaults.baseURL = 'http://localhost:8000/api';
import Vue from 'vue'
import router from './router'
//import dateHelpers from '@/helpers/date-helpers'
import VueSimpleAlert from "vue-simple-alert";
import store from './store';
// import plugin
import VueToastr from "vue-toastr";
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue'
import mixin from  './mixin.js'
import VueTelInput from 'vue-tel-input';
import vSelect from 'vue-select';
import BootstrapVue from "bootstrap-vue"

import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue-select/dist/vue-select.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'sweetalert2/dist/sweetalert2.min.css';

import 'jquery/dist/jquery.min'
import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min'
import 'bootstrap-vue/dist/bootstrap-vue.css'

//import Vue Router

Vue.use(VueSimpleAlert);
Vue.use(VueSweetalert2);
Vue.use(VueTelInput);
Vue.component('v-select', vSelect)
Vue.use(BootstrapVue)
// use plugin
Vue.use(VueToastr, {defaultTimeout: 1000,defaultPosition: "toast-top-center",});
/* Vue.use(Toastr, { 
	defaultTimeout: 3000,
	defaultProgressBar: false,
	defaultProgressBarValue: 0,
	defaultType: "error",
	defaultPosition: "toast-bottom-left",
	defaultCloseOnHover: false,
	defaultStyle: { "background-color": "red" }
}) */

//import bootstrap css & js
//import 'bootstrap'
/* import 'bootstrap/dist/css/bootstrap.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import 'jquery/dist/jquery.min'
import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min' */

//import components

 import ContentHeader from './components/ContentHeader.vue'
import Content from './components/Content.vue' 
import Message from './components/Message.vue'
Vue.component('Message', Message)
Vue.component("ContentHeader", ContentHeader)
Vue.component("Content", Content)

/* const TheContainer = () => import('@/containers/TheContainer') */

Vue.config.productionTip = false

/* export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
  base: process.env.BASE_URL,
}) */


  /* const token = window.sessionStorage['token-'+(mixin.data()).appName];
  const userRole = window.sessionStorage.userRole;
  const userData = window.sessionStorage.userData;
  if(to.name !== 'Login' && to.name !== '404' && (!token || !userRole || !userData) ) {
    window.sessionStorage.clear();
      next({ name: 'Login' });
  } else {
    next();
  } */




/*  router.beforeEach((to, from, next) => {
  console.log('halo');
  console.log(to+ '  '+from);
  const token = window.sessionStorage.token;
  const userData = window.sessionStorage.userData;
  if(to.name !== 'login' && to.name !== '404' && (!token || !userData)) {
  //if(to.name !== '404' && (!token || !userData)) {
    window.sessionStorage.clear();
      next({ name: 'login' });
        //next();
  } else {
    next();
  }

})  */

//import axios
//import axios from 'axios'
Vue.mixin(mixin);
//set credential axios with true
//axios.defaults.withCredentials = true
/* Vue.directive('uppercase', {
	update (el) {
		el.value = el.value.toUpperCase()
	},
}); */
Vue.directive('uppercase1', function (value) {
  //console.log(value);
  //value.toUpperCase;
  //value.toUpperCase;
	//return value.toUpperCase;
	return value;
});

Vue.directive('uppercase', {
	update (el) {
		el.value = el.value.toUpperCase()
	},
})

/* Vue.directive('uppercase', {
  bind (el, _, vnode) {
    el.addEventListener('input', (e) => {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
      })
    }
  }); */

let vs =new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

global.vs = vs;


