import MainLayout from '../components/layout/MainLayout.vue'

const reports = () => {
    return [
        {
            path: '/rep',
            name: 'Report',
            meta: {menu: 'set'},
            //label: 'Setup',
            //icon: 'cil-settings',
            component: MainLayout,
            children: [
                {
                    path: '/rep_pos_stok', name: 'rep_pos_stok', meta: {menu: 'rep_pos_stok'},
                    component: () => import(  "@/views/pages/report/posisi_stok/Index"),
                },
                {
                    path: '/rep_kartu_stok', name: 'rep_kartu_stok', meta: {menu: 'rep_kartu_stok'},
                    component: () => import(  "@/views/pages/report/kartu_stok/Index"),
                },
            
                 
            ],
        },
    ]
};

export {
    reports,
}