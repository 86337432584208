/* const defaultErrors = error => {
    var msg = ''
    for (var key in error) {
        msg = msg + '>>> ' + error[key] + '<br>'
    }
    return msg;
};
window.axios.defaults.headers.common['x-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
 */   
import { defaultErrors } from "./helpers";

export default {
    
    data() {
        return {
            appName: "ATK",
            //urlApi : location.protocol+'//localhost:8000/api/',
            //urlApi : location.protocol+'//atk.mytocco.xyz/api/', // utk di server
            urlApi : 'https://atk.mytocco.xyz/api/', // utk di server
            //urlOnly : location.protocol+'//atk.mytocco.xyz/', //utk di server
            urlOnly : 'https://atk.mytocco.xyz/',
            headers : { 
                "Access-Control-Allow-Origin": "*" 
            } 
             
        };
    },
    beforeMount() {
        switch (window.location.host) {
            case 'localhost':
                //this.urlApi = location.protocol+'//localhost:8000/api/';
                //this.urlApi = location.protocol+'//atk.mytocco.xyz/api/';
                this.urlApi = 'https://atk.mytocco.xyz/api/';
                break;
            default:
                //this.urlApi = location.protocol+'//localhost:8000/api/';
                //this.urlApi = location.protocol+'//atk.mytocco.xyz/api/';
                 this.urlApi = 'https://atk.mytocco.xyz/api/';
                break;
        }
      },
    methods: {
/*         defaultErrors (error)  {
            var msg = ''
            for (var key in error) {
                msg = msg + '>>> ' + error[key] + '<br>'
            }
            return msg;
        }, */
        async apiGets(url, params = {}) {
            url=this.urlApi+url;
            try {
                const response = await axios.get(url, params, {}, this.headers );
                return {
                    data	:   response.data,
                    status	:   response.status
                }
            } catch (error) {
                console.log(error);
                //toastr.error( defaultErrors(error.response.data.errors) );
                //this.$toastr.e( defaultErrors(error.response.data.errors) );
                return {
                    data	:   error.response.data,
                    status	:	error.response.status
                }   
            }
        },
        async apiPostsAwal(url, params = {}) {
            url=this.urlApi+url;
            try {
                const response = await axios.post(url, params, {}, this.headers);
                return {
                    data	:   response.data,
                    status	:   response.status
                }
            } catch (error) {
                //toastr.error( defaultErrors(error.response.data.errors) );
                 
                return {
                    data	:   error.response.data,
                    status	:	error.response.status
                }   
            }
        },
        async apiPosts(url, params = {}) {
            url=this.urlApi+url;
            try {
                const response = await axios.post(url, params, {}, this.headers);
                return {
                    data	:   response.data,
                    status	:   response.status
                }
            } catch (error) {
                //toastr.error( defaultErrors(error.response.data.errors) );
                 console.log('error mixin');
                 console.log(error);
                 console.log(defaultErrors(error.response.data.errors));
                return {
                    data	:   error.response.data,
                    status	:	error.response.status
                }   
            }
            /* (error) => {
                this.allerros = error.response.data.errors;
                this.success = false;
           } */
        },
        async apiDeletes(url, params = {}) {
            url=this.urlApi+url;
            try {
                const response = await axios.delete(url, params, {}, this.headers);
                return {
                    data	:   response.data,
                    status	:   response.status
                }
            } catch (error) {
                //toastr.error( defaultErrors(error.response.data.errors) );
                return {
                    data	:   error.response.data,
                    status	:	error.response.status
                }   
            }
        },
        async apiPatchs(url, params = {}) {
            url=this.urlApi+url;
            try {
                const response = await axios.patch(url, params, {}, this.headers);
                return {
                    data	:   response.data,
                    status	:   response.status
                }
            } catch (error) {
                //toastr.error( defaultErrors(error.response.data.errors) );
                return {
                    data	:   error.response.data,
                    status	:	error.response.status
                }   
            }
        },
        async apiReports(url, params = {}) {
            url=this.urlApi+url;
            const headers = { 
                responseType: 'blob',
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/zip'
                }
             }
            let loader = vm.$loading.show({
                "z-index": 999999,
            });
            try {
                //const response = await axios.post(this.apiOperationUrl + url, params, headers);
                const response = await axios.post(url, params, {}, this.headers);
                //loader.hide();
                return {
                    data	:   response.data,
                    status	:   response.status
                }
            } catch (error) {
                /* loader.hide();
                this.$notify({
                    group: 'notification',
                    type: 'error',
                    title: "Warning!",
                    text: defaultErrors(error.response.data.errors)
                }); */
                return {
                    data	:   error.response.data,
                    status	:	error.response.status,
                    //error   :   error.response.statusText,
                }   
            }
        },
        downloadFile(blob, type = 'application/pdf', name = 'Report.pdf'){
            var newBlob = new Blob([blob], {type: type})

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            } 

            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download= name;
            link.click();
            setTimeout(function(){
                window.URL.revokeObjectURL(data);
            }, 100);
        },
        formatInputDate(date, join = '/') {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [month, day, year].join( join );
        },
    }
}
