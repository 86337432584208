<template>
    <li class="nav-item">
        <router-link class="nav-link" :to="link" :class="{ active : isActive() }" >
                <!-- <i class :class="icon" v-if="icon"></i> -->
                <i class="nav-icon " :class="icon" v-if="icon"></i>
                <p><slot /></p>
        </router-link>
              <!-- <a href="#" class="nav-link">
                <i class="fas fa-circle nav-icon"></i>
                <p>Level 1</p>
              </a> -->
   </li>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: null
        },
         link: {
            type: [String, Object],
            default: null
        }
    },
    methods: {
        isActive(){
            if(typeof this.link=== "object") {
                return this.$route.name=== this.link.name;
            } else {
                return this.$route.path=== this.link;
            }
        }
    }  
}
</script>