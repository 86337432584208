<template>
    <div class="items">
        <div class="container mt-5">
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            EDIT ITEMS
                        </div>
                        <div class="card-body">
                            <form @submit.prevent="itemUpdate">

                                <div class="form-group">
                                    <label>KODE</label>
                                    <!-- <input type="text" class="form-control" v-model="item.kode"
                                           placeholder="Masukkan Title"> -->
                                     {{ item.kode }}      
                                    <div v-if="validation.kode">
                                        <div class="alert alert-danger mt-1" role="alert">
                                            {{ validation.kode[0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>NAMA</label>
                                    <textarea class="form-control" v-model="item.nama" rows="5"
                                              placeholder="Masukkan Konten"></textarea>
                                    <div v-if="validation.nama">
                                        <div class="alert alert-danger mt-1" role="alert">
                                            {{ validation.nama[0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>KET</label>
                                    <textarea class="form-control" v-model="item.ket" rows="5"
                                              placeholder="Masukkan Konten"></textarea>
                                    <div v-if="validation.ket">
                                        <div class="alert alert-danger mt-1" role="alert">
                                            {{ validation.ket[0] }}
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <button type="submit" class="btn btn-md btn-success mr-2">UPDATE</button>
                                    <button type="reset" class="btn btn-md btn-danger">RESET</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                item: {},
                validation: []
            }
        },
        created() {
            axios.get(this.urlApi+`items/${this.$route.params.id}`)
                .then((response) => {
                this.item = response.data.data;
            });
        },
        methods: {
            itemUpdate() {
                axios.put(this.urlApi+`items/${this.$route.params.id}`, this.item)
                    .then((response) => {
                        this.$router.push({
                            name: 'items'
                        });
                        console.log(response);
                    }).catch(error => {
                    this.validation = error.response.data.data;
                });
            }
        }
    }
</script>