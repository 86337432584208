import MainLayout from '../components/layout/MainLayout.vue'

const setups = () => {
    return [
        {
            path: '/set',
            name: 'Setup',
            meta: {menu: 'set'},
            //label: 'Setup',
            //icon: 'cil-settings',
            component: MainLayout,
            children: [
                {
                    path: '/set_item', name: 'item', meta: {menu: 'set_item'},
                    component: () => import(  "@/views/pages/setup/master/item/Index"),
                },
                {
                    path: '/set_item/create', name: 'Setup Item Create', meta: {menu: 'set_item'},
                    component: () => import(  "@/views/pages/setup/master/item/Create"),
                },
                {
                    path: '/set_item/edit/:id', name: 'Setup Item Edit', meta: {menu: 'set_item'},
                    component: () => import(  "@/views/pages/setup/master/item/Edit"),
                  },
                {
                    path: '/set_gudang', name: 'gudang', meta: {menu: 'set_gudang'},         
                    component: () => import(  "@/views/pages/setup/master/gudang/Index"),
                },
                {
                    path: '/set_gudang/create', name: 'Setup Gudang Create', meta: {menu: 'set_gudang'},
                    component: () => import(  "@/views/pages/setup/master/gudang/Create"),
                },  
                { path: '/set_gudang/edit/:id', name: 'Setup Gudang Edit', meta: {menu: 'set_gudang'},
                component: () => import(  "@/views/pages/setup/master/gudang/Edit"), },
                {
                    path: '/set_supplier', name: 'supplier', meta: {menu: 'set_supplier'},         
                    component: () =>
                      import(  "@/views/pages/setup/master/supplier/Index"),
                  }, 
                {
                    path: '/set_supplier/create', name: 'Setup Supplier Create', meta: {menu: 'set_supplier'},
                    component: () => import(  "@/views/pages/setup/master/supplier/Create"),
                },  
                { path: '/set_supplier/edit/:id', name: 'Setup Supplier Edit', meta: {menu: 'set_supplier'},
                component: () => import(  "@/views/pages/setup/master/supplier/Edit"), },
                 
            ],
        },
    ]
};
/* const setups = () => {
    return [
        {
            path: '/set',
            name: 'Setup',
            label: 'Setup',
            icon: 'cil-settings',
            component: TheContainer,
            children: [
                {
                    path: '/set_mas',
                    name: 'Setup Master',
                    label: 'Master',
                    icon: 'cil-book',
                    component: () => import('@/views/Main.vue'),
                    ...authenticateRoutes([
                        {
                            path: 'set_mas_approval_structure',
                            role: 'set_mas_approval_structure',
                            name: 'Setup Master Approval Structure',
                            label: 'Approval Structure',
                            component: () => import("@/views/Setup/Master/Approval Structure/Landing.vue"),
                            create: false,
                            update: null
                        },
                        {
                            path: 'set_mas_budget',
                            role: 'set_mas_budget',
                            name: 'Setup Master Budget',
                            label: 'Budget',
                            component: () => import("@/views/Setup/Master/Budget/Landing.vue"),
                            create: false,
                            update: null,
                        },
                        {
                            path: 'set_mas_business_unit',
                            role: 'set_mas_business_unit',
                            name: 'Setup Master Business Unit',
                            label: 'Business Unit',
                            component: () => import("@/views/Setup/Master/Business Unit/Landing.vue"),
                            create: false,
                            update: null,
                        },
                        {
                            path: 'set_mas_category',
                            role: 'set_mas_category',
                            name: 'Setup Master Category',
                            label: 'Category',
                            component: () => import("@/views/Setup/Master/Category/Landing.vue"),
                            create: false,
                            update: null,
                        },
                        {
                            path: 'set_mas_department',
                            role: 'set_mas_department',
                            name: 'Setup Master Department',
                            label: 'Department',
                            component: () => import("@/views/Setup/Master/Department/Landing.vue"),
                            create: false,
                            update: null,
                        },
                        {
                            path: 'set_mas_depo',
                            role: 'set_mas_depo',
                            name: 'Setup Master Depo',
                            label: 'Depo',
                            component: () => import("@/views/Setup/Master/Depo/Landing.vue"),
                            create: false,
                            update: null,
                        },
                        {
                            path: 'set_mas_employee',
                            role: 'set_mas_employee',
                            name: 'Setup Master Employee',
                            label: 'Employee',
                            component: () => import("@/views/Setup/Master/Employee/Landing.vue"),
                            create: false,
                            update: null,
                        },
                        {
                            path: 'set_mas_faktur_pajak',
                            role: 'set_mas_faktur_pajak',
                            name: 'Setup Master Faktur Pajak',
                            label: 'Faktur Pajak',
                            component: () => import("@/views/Setup/Master/Faktur Pajak/Landing.vue"),
                            create: false,
                            update: null,
                        },
                        {
                            path: 'set_mas_menu',
                            role: 'set_mas_menu',
                            name: 'Setup Master Form Menu',
                            label: 'Form Menu',
                            component: () => import("@/views/Setup/Master/Form Menu/Landing.vue"),
                            create: false,
                            update: null,
                        },
                        {
                            path: 'set_mas_general',
                            role: 'set_mas_general',
                            name: 'Setup Master General',
                            label: 'General',
                            component: () => import("@/views/Setup/Master/General/Landing.vue"),
                            create: false,
                            update: null,
                        },
                        {
                            path: 'set_mas_item',
                            role: 'set_mas_item',
                            name: 'Setup Master Item',
                            label: 'Item',
                            component: () => import("@/views/Setup/Master/Item/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_item_assign',
                            role: 'set_mas_item_assign',
                            name: 'Setup Master Item Assign',
                            label: 'Item Assign',
                            component: () => import("@/views/Setup/Master/Item Assign/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_lain_lain',
                            role: 'set_mas_lain_lain',
                            name: 'Setup Master Other',
                            label: 'Lain-lain',
                            component: () => import("@/views/Setup/Master/Other/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_division_productline',
                            role: 'set_mas_division_productline',
                            name: 'Setup Master Division',
                            label: 'Product Line',
                            component: () => import("@/views/Setup/Master/Division/Landing.vue"),
                            create: false
                        }, 
                        {
                            path: 'set_mas_rate',
                            role: 'set_mas_rate',
                            name: 'Setup Master Rate',
                            label: 'Rate',
                            component: () => import("@/views/Setup/Master/Rate/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_role',
                            role: 'set_mas_role_olis',
                            name: 'Setup Master Role',
                            label: 'Role',
                            component: () => import("@/views/Setup/Master/Role/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_sales_price',
                            role: 'set_mas_sales_price',
                            name: 'Setup Master Sales Price',
                            label: 'Sales Pricelist Upload',
                            component: () => import("@/views/Setup/Master/Sales Price/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_service',
                            role: 'set_mas_service',
                            name: 'Setup Master Service',
                            label: 'Service',
                            component: () => import("@/views/Setup/Master/Service/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_standard_price',
                            role: 'set_mas_standard_price',
                            name: 'Setup Master Standard Price',
                            label: 'Standard Price',
                            component: () => import("@/views/Setup/Master/Standard Price/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_structure',
                            role: 'set_mas_structure',
                            name: 'Setup Master Structure',
                            label: 'Structure',
                            component: () => import("@/views/Setup/Master/Structure/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_user',
                            role: 'set_mas_user',
                            name: 'Setup Master User',
                            label: 'User',
                            component: () => import("@/views/Setup/Master/User/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_user_group',
                            role: 'set_mas_user_group',
                            name: 'Setup Master User Group',
                            label: 'User Group',
                            component: () => import("@/views/Setup/Master/User Group/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_user_role',
                            role: 'set_mas_user_role',
                            name: 'Setup Master User Role',
                            label: 'User Role',
                            component: () => import("@/views/Setup/Master/User Role/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_warehouse',
                            role: 'set_mas_warehouse',
                            name: 'Setup Master Warehouse',
                            label: 'Warehouse',
                            component: () => import("@/views/Setup/Master/Warehouse/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_warehouse_assign',
                            role: 'set_mas_warehouse_assign',
                            name: 'Setup Master Warehouse Assign',
                            label: 'Warehouse Assign',
                            component: () => import("@/views/Setup/Master/Warehouse Assign/Landing.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_upload_excel',
                            role: 'set_mas_upload_excel',
                            name: 'Setup Master Upload Excel Production',
                            label: 'Upload Excel Production',
                            component: () => import("@/views/Setup/Master/Upload Excel Production/Form.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_upload_excel_accounting',
                            role: 'set_mas_upload_excel_accounting',
                            name: 'Setup Master Upload Excel Accounting',
                            label: 'Upload Excel Accounting',
                            component: () => import("@/views/Setup/Master/Upload Excel Accounting/Form.vue"),
                            create: false
                        },
                        {
                            path: 'set_mas_upload_file',
                            role: 'set_mas_upload_file',
                            name: 'Setup Master Upload File RPT',
                            label: 'Upload File',
                            component: () => import("@/views/Maintenance/Master/Upload File/Form.vue"),
                            create: false,
                            update: null,
                        },
                    ]),
                },
                {
                    path: '/set_rep',
                    name: 'Setup Report',
                    label: 'Report',
                    icon: 'cil-clipboard',
                    component: () => import("@/views/Main.vue"),
                    ...authenticateRoutes([
                        {
                            path: 'set_rep_item',
                            role: 'set_rep_item',
                            name: 'Setup Report Item',
                            label: 'Item',
                            component: () => import("@/views/Setup/Report/Item/Form.vue"),
                            create: false,
                        },
                        {
                            path: 'set_rep_form_menu',
                            role: 'set_rep_form_menu',
                            name: 'Setup Report Form Menu',
                            label: 'User Menu',
                            component: () => import("@/views/Setup/Report/User Menu/Form.vue"),
                            create: false,
                        },
                        {
                            path: 'set_rep_notification',
                            role: 'set_rep_notification',
                            name: 'Setup Report Notification',
                            label: 'Pending Notification',
                            component: () => import("@/views/Setup/Report/Pending Notification/Form.vue"),
                            create: false,
                        },
                        {
                            path: 'set_rep_apphistory',
                            role: 'set_rep_apphistory',
                            name: 'Setup Report Approval History',
                            label: 'Laporan Approval History',
                            component: () => import("@/views/Setup/Report/Approval History/Form.vue"),
                            create: false,
                        },
                    ])
                },
            ],
        },
    ]
}; */

export {
    setups,
}