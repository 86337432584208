<template>
    <div class="content-wrapper">
    <!-- <section class="content"> -->
        <div class="items">
        <!-- <div class="container-fluid">     -->
            <div class="container mt-5">
                <div class="row justify-content-center">
                 
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                Item {{ menu }} halo
                                     <!-- <h3 class="card-title">
                                        ITEMS ku
                                    </h3> -->
                            </div>
                            <div class="card-body">
                                <router-link :class="['btn btn-md btn-success mb-2']" to="/create">TAMBAH item d</router-link>
                                <hr>
    
                                <div class="table-responsive mt-2">
                                    <table class="table table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th>KODE</th>
                                            <th>NAMA</th>
                                            <th>KET</th>
                                            <th>AKSI</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        
                                        <tr v-for="item in items" :key="item.id">
                                            <td>{{ item.kode }}</td>
                                            <td>{{ item.nama }}</td>
                                            <td>{{ item.ket }}</td>
                                            <td class="text-center">
                                                <!-- <router-link class="dropdown-item" :to="'products/' + item.id + '/edit'">Edit</router-link> -->
                                                <router-link class="dropdown-item" :to="'/set_item/edit/' + item.id ">Edit</router-link>
                                                <!-- <router-link :to="{name: 'edit', params: { id: item.id }}" class="btn btn-sm btn-primary mr-2">EDIT</router-link> -->
                                                <button @click.prevent="itemDelete(item.id)" class="btn btn-sm btn-danger">HAPUS</button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>
        </div>
    <!-- </section> -->
    </div>
</template>


<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                items: []
            }
        },
        created() {
            var headers = { 
                "Access-Control-Allow-Origin": "*" 
            } 
            /* var headers= {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET, item, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
            } */
            //axios.get('http://localhost:8000/api/items', {}, headers).then(response => {
            axios.get(this.urlApi+'items', {}, headers).then(response => {
                this.items = response.data.data;
               // console.log(this.items);
            });
            
        },
         methods: {
            itemDelete(id)
            {
                //axios.delete(`http://localhost:8000/api/items/${id}`)
                axios.delete(this.urlApi+`items/${id}`)
                    .then(response => {
                        this.items.splice(this.items.indexOf(id), 1);
                        console.log(response);
                    }).catch(error => {
                    console.log(error.response);
                });
            }
        }
    }
</script>