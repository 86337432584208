const relativeUrl = url => {
	if (url.charAt(0) != '/') {
		url = `/${url}`
	}

	return `${url}`
}

const getPage = route => {
	if ('page' in route.query) {
		return route.query.page;
	}

	return '';
}

const moneyTemplate = () => ({
	decimal: ',',
	thousands: '.',
	prefix: 'Rp ',
	precision: 0,
	masked: false,
});

const defaultErrors = error => {
	var msg = ''
	for (var key in error) {
		msg = msg + '>>> ' + error[key] + '<br>'
	}
	return msg;
};

export {
	relativeUrl,
	getPage,
	moneyTemplate,
	defaultErrors
}
