import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const getDefaultState = () => {
  return {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    darkMode: false,
    app: {
        name : "Vue Tocco"
    }
  }
}

const state = getDefaultState();

export default new Vuex.Store({
    state,
    //mutations
  })