<template>
 
     <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="index3.html" class="brand-link">
        <img
          src="/adminlte/img/AdminLTELogo.png"
          :alt="$store.state.app.name"
          class="brand-image img-circle elevation-3"
          style="opacity: 0.8"
        />
        <span class="brand-text font-weight-light">{{ $store.state.app.name }}</span>
      </a>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
            <img
              src="/adminlte/img/user2-160x160.jpg"
              class="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div class="info">
            <a href="#" class="d-block">Alexander Pierce</a>
          </div>
        </div>

        <!-- SidebarSearch Form -->
        <div class="form-inline">
          <div class="input-group" data-widget="sidebar-search">
            <input
              class="form-control form-control-sidebar"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div class="input-group-append">
              <button class="btn btn-sidebar">
                <i class="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <ul
            class="nav nav-pills nav-sidebar nav-child-indent  flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
          <navitem icon="fas fa-home" link="/home">
               Home Asli
            </navitem>
          <navitem icon="fas fa-home" link="/tes">
               Tes
          </navitem>  
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-table"></i>
              <p>
                Setup
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <navitem icon="far fa-circle" link="/set_item">
                  Item
              </navitem>
              <navitem icon="far fa-circle" link="/set_gudang">
                  Gudang
              </navitem>
              <navitem icon="far fa-circle" link="/set_supplier">
                  Supplier
              </navitem>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-edit"></i>
              <p>
                Transaksi
                <!-- <i class="fas fa-angle-left right"></i> -->
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <navitem icon="far fa-circle" link="/tran_atk_in">
                  Atk Masuk
              </navitem>
              <navitem icon="far fa-circle" link="/tran_atk_out">
                  Atk Keluar
              </navitem>
            </ul>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link">
              <i class="nav-icon fas fa-book"></i>
              <p>
                Laporan
                <i class="fas fa-angle-left right"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">               
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="nav-icon fas fa-circle"></i>
                  <p>
                    Setup
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>                 
                <ul class="nav nav-treeview">
                  <li class="nav-item">
                    <a href="#" class="nav-link">
                      <i class="far fa-circle nav-icon"></i>
                      <p>User</p>
                    </a>
                  </li>
                  <navitem icon="far fa-circle" link="/rep_item">
                    Item
                  </navitem>
                </ul> 
              </li>  
             
             
            </ul>
            <ul class="nav nav-treeview">               
             <navitem icon="far fa-circle" link="/gudang">
                                Keluar
              </navitem>
            </ul>
            <ul class="nav nav-treeview">               
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="nav-icon fas fa-circle"></i>
                  <p>
                    Transaksi
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>                 
                <ul class="nav nav-treeview">                   
                  <navitem icon="far fa-circle" link="/rep_item">
                    Atk Masuk
                  </navitem>
                  <navitem icon="far fa-circle" link="/rep_item">
                    Atk Keluar
                  </navitem>
                </ul> 
              </li>  
 
            </ul>
            <ul class="nav nav-treeview">               
              <li class="nav-item">
                <a href="#" class="nav-link">
                  <i class="nav-icon fas fa-circle"></i>
                  <p>
                    Rekap
                    <i class="right fas fa-angle-left"></i>
                  </p>
                </a>                 
                <ul class="nav nav-treeview">                   
                  <navitem icon="far fa-circle" link="/rep_pos_stok">
                    Posisi Stok
                  </navitem>
                  <navitem icon="far fa-circle" link="/rep_kartu_stok">
                    Kartu Stok
                  </navitem>
                </ul> 
              </li>  
 
            </ul>
          </li>

          </ul>
        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
 
</template>

<script>
import Navitem from '../partials/Navitem.vue'

export default {
  components: { Navitem },
  setup() {
    
  },
}
</script>