import MainLayout from '../components/layout/MainLayout.vue'

const transactions = () => {
    return [
        {
            path: '/tran',
            name: 'Transaction',
            meta: {menu: 'set'},
            //label: 'Setup',
            //icon: 'cil-settings',
            component: MainLayout,
            children: [
                {
                    path: '/tran_atk_in', name: 'tran_atk_in', meta: {menu: 'tran_atk_in'},
                    component: () => import(  "@/views/pages/transaction/masuk/Index"),
                },
                {
                    path: '/tran_atk_in/create', name: 'tran_atk_in Create', meta: {menu: 'tran_atk_in'},
                    component: () => import(  "@/views/pages/transaction/masuk/Create"),
                },
                {
                    path: '/tran_atk_in/edit/:id', name: 'tran_atk_in Edit', meta: {menu: 'tran_atk_in'},
                    component: () => import(  "@/views/pages/transaction/masuk/Edit"),
                  },
                /// out
                  {
                    path: '/tran_atk_out', name: 'tran_atk_out', meta: {menu: 'tran_atk_out'},
                    component: () => import(  "@/views/pages/transaction/keluar/Index"),
                },
                {
                    path: '/tran_atk_out/create', name: 'tran_atk_out Create', meta: {menu: 'tran_atk_out'},
                    component: () => import(  "@/views/pages/transaction/keluar/Create"),
                },
                {
                    path: '/tran_atk_out/edit/:id', name: 'tran_atk_out Edit', meta: {menu: 'tran_atk_out'},
                    component: () => import(  "@/views/pages/transaction/keluar/Edit"),
                  }, 
            
                 
            ],
        },
    ]
};

export {
    transactions,
}