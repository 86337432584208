<template>
  <div class="wrapper">
    <navbar />
    <sidebar />
    <!-- Main Sidebar Container -->
   

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">
       <router-view>
        
       </router-view>

     
    </div>
    <!-- /.content-wrapper -->
    <footer class="main-footer">
      <strong
        >Copyright &copy; 2014-2021
        <a href="https://adminlte.io">AdminLTE.io</a>.</strong
      >
      All rights reserved.
      <div class="float-right d-none d-sm-inline-block">
        <b>Version</b> 3.1.0
      </div>
    </footer>

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Control sidebar content goes here -->
    </aside>
    <!-- /.control-sidebar -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
import Navbar from "./partials/Navbar.vue";
import Sidebar from "./partials/Sidebar.vue";
export default {
  components: { Navbar, Sidebar },
  setup() {},
};
</script>
