import Vue from 'vue'
import VueRouter from 'vue-router'
//import Router from 'vue-router'

//menggunkan Vue Router di Vue JS

Vue.use(VueRouter);

import ItemsIndex from '../components/items/Index'
import ItemsCreate from '../components/items/Create'
import ItemsEdit from '../components/items/Edit'
import MainLayout from '../components/layout/MainLayout.vue'

import { setups } from '@/router/setup';
import { transactions } from '@/router/transaction';
import { reports } from '@/router/report';

//const router = new VueRouter({
  //base: process.env.BASE_URL,
export default new VueRouter  ({
    
    routes: [
      {
        path: '/',
        name: 'login1',
        //component: ItemsIndex
        component: () =>
        import( /* webpackChunkName: "Auth" */ "@/views/auth/Index"),
      },
      {
        path: '/items',
        name: 'items1',
        component: ItemsIndex
      },
      {
        path: '/adminlte1',
        name: 'adminlte1',
        component: () =>
            import( /* webpackChunkName: "Auth" */ "@/views/adminlte/Index01"),
      },
      
      {
        path: '/create',
        name: 'create',
        component: ItemsCreate
      },
      {
        path: '/edit/:id',
        name: 'edit',
        component: ItemsEdit
      },
      {
        path: '/homelama',
        name: 'homelama',
        component: () =>
            import( /* webpackChunkName: "Auth" */ "@/views/home/Index"),
      },
      {
          path: '/login',
          name: 'login',
          component: () =>
              import( /* webpackChunkName: "Auth" */ "@/views/auth/Index"),
      },
      {
        path: '/main',
        name: 'main',
        component: MainLayout,
        children:[
          {
            path: '/home',
            name: 'home',          
            component: () =>
              import(  "@/views/adminlte/Indexlte"),
          },
          {
            path: '/dashboard',
            name: 'dashboard',
            component: () =>
                import( /* webpackChunkName: "Auth" */ "@/views/dashboard/Index"),
          },
          {
            path: '/tes',
            name: 'tes',          
            component: () =>
              import(  "@/views/pages/transaction/tes/Index"),
          },
/*           {
            path: '/item',
            name: 'item',          
            component: () =>
              import(  "@/views/pages/setup/master/item/Index"),
          }, */
          {
            path: '/gudang1',
            name: 'gudang1',          
            component: () =>
              import(  "@/views/pages/setup/master/gudang/Index"),
          },
          
          {
            path: '/homelte',
            name: 'homelte',
            component: () =>
                import( /* webpackChunkName: "Auth" */ "@/views/adminlte/HomeView"),
          }
        ]
  
    },
      {
          path: '/1dashboard',
          name: '1dashboard',
          //component: MainLayout,
          component: () =>
              import( /* webpackChunkName: "Auth" */ "@/views/dashboard/Index"),
      },
      {
        path: '/homelte1',
        name: 'homelte1',
        component: () =>
            import( /* webpackChunkName: "Auth" */ "@/views/adminlte/HomeView"),
      }
      ,
      ...setups()
      ,
      ...transactions()
      ,
      ...reports(),
    ],
    mode: 'history'
  })

  /* export default new VueRouter({
    mode: 'history', // https://router.vuejs.org/api/#mode
    //linkActiveClass: 'active',
    //scrollBehavior: () => ({ y: 0 }),
    routes: router,
    base: process.env.BASE_URL,
  }) */